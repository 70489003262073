import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';


const routes: Routes = [
  // { path: '', redirectTo: '**', pathMatch: 'full' },
  // { path: '**', component: HomeComponent  },
  { path : '**',pathMatch : 'full',component : HomeComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
