import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {

  constructor() { }

  setData(data: any) {
    localStorage.setItem('meta_data', JSON.stringify(data));
  }

  

  setToken(token: string) {
    localStorage.setItem('meta_token', token);
  }

  getData() {
    return JSON.parse(localStorage.getItem('meta_data'));
  }



  getToken() {
   return localStorage.getItem('meta_token');
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }



 

  saveClient(data: any) {
    localStorage.setItem('meta_new_client', JSON.stringify(data));
  }

  getClient() {
    return JSON.parse(localStorage.getItem('meta_new_client'));
  }

  logout() {
    localStorage.removeItem('meta_token');
    localStorage.removeItem('meta_data');
    localStorage.removeItem('meta_new_client');
  }
}
