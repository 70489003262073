import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable()

export class ApiService {

  constructor(private http: HttpClient) {}

  apiUrl: any = environment.apiUrl;

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  addQuery(data): Observable < any > {
    return this.http.post(`${this.apiUrl}/user/addLead`, data).pipe(map(this.extractData));
  }
}
