import { Component, OnInit,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
// import { PageScrollService } from 'ngx-page-scroll-core';
import { ApiService } from '../../_services';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  myForm: any = {};
  loading : boolean = false;
  submitted : boolean = false;
  selectUndefinedOptionValue : any;
  userMessage : string = "";



  constructor(private apiService: ApiService,) {}

  ngOnInit() {
  }

  scrollToElement(target): void {
    const element = document.querySelector(target)
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  onSubmit(){
    this.userMessage = "Your query has been sent successfully. we will get back to you shortly.";   
    // if(this.loading) return;
    // this.submitted = true;
    // if(this.myForm.name && this.myForm.phone && this.myForm.service){
    //   this.loading = true;
    //   this.apiService.addQuery(this.myForm).subscribe(objS => { 
    //     if (objS.status == 200) {
    //       this.myForm = {};
    //       this.userMessage = "Your query has been sent successfully. we will get back to you shortly.";   
    //     }
    //     this.loading = false;
    //     this.submitted = false;
    //    },(objE)=>{

    //   })
    // }
  }

}

